<template>
  <!-- NAME[epic=绩效] 提成方案设置 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depart_id"
          clearable
          style="width: 140px"
          placeholder="部门"
        >
          <el-option
            v-for="(i, idx) in departmentSelectList"
            :key="idx"
            :value="i.id"
            :label="i.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input v-model="form.name" placeholder="姓名、手机号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="handlePlan">管理提成方案</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '销售提成方案'">
            <el-select
              v-model="row.sale_id"
              clearable
              placeholder="销售提成方案"
              @change="handleChange($event, row, 1)"
            >
              <el-option
                v-for="(i, idx) in saleSelect"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
            </el-select>
          </div>
          <div v-else-if="item.label == '送货提成方案'">
            <el-select
              v-model="row.back_id"
              clearable
              placeholder="送货提成方案"
              @change="handleChange($event, row, 2)"
            >
              <el-option
                v-for="(i, idx) in deliverySelect"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
            </el-select>
          </div>
          <div v-else-if="item.label == '调拨提成方案'">
            <el-select
              v-model="row.allot_id"
              clearable
              placeholder="调拨提成方案"
              @change="handleChange($event, row, 3)"
            >
              <el-option
                v-for="(i, idx) in allotSelect"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
            </el-select>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import {
    departmentList,
    staffData,
    commissionPlanSelect,
  } from '@/api/performance'
  import { postAction } from '@/api/Employee'
  import _ from 'lodash'
  export default {
    name: 'SetCommission',
    components: {},
    data() {
      return {
        loading: false,
        list: [],
        departmentSelectList: [],
        // 表格提成方案下拉
        saleSelect: [],
        deliverySelect: [],
        allotSelect: [],
        total: 0,
        form: {
          depart_id: '',
          name: '',
          pageSize: 10,
          pageNo: 1,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '部门',
          '姓名',
          '角色',
          '销售提成方案',
          '送货提成方案',
          // '调拨提成方案',
        ],
        columns: [
          {
            order: 1,
            label: '部门',
            prop: 'depart_name',
            width: '150',
          },
          {
            order: 2,
            label: '姓名',
            prop: 'user_name',
            width: '110',
          },
          {
            order: 3,
            label: '角色',
            prop: 'role_name',
            width: '140',
          },
          {
            order: 4,
            label: '销售提成方案',
            prop: '',
            width: '160',
          },
          {
            order: 5,
            label: '送货提成方案',
            prop: '',
            width: '160',
          },
          // {
          //   order: 6,
          //   label: '调拨提成方案',
          //   prop: '',
          //   width: '160',
          // },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.initSelect()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await staffData(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      // 初始化下拉
      initSelect() {
        // 部门下拉
        departmentList().then((res) => {
          this.departmentSelectList = res.data.list
        })
        // 表格下拉
        // 类别2业务员提成3送货员提成4调拨提成
        commissionPlanSelect({ pageNo: 1, pageSize: -1, type: 2 }).then((r) => {
          this.saleSelect = r.data
        })
        commissionPlanSelect({ pageNo: 1, pageSize: -1, type: 3 }).then((r) => {
          this.deliverySelect = r.data
        })
        commissionPlanSelect({ pageNo: 1, pageSize: -1, type: 4 }).then((r) => {
          this.allotSelect = r.data
        })
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handlePlan() {
        this.$router.push({
          path: 'commissionPlanManagement',
        })
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleChange(val, row, type = 1) {
        let field = ''
        if (type == 1) {
          // 销售
          field = 'sale_id'
        } else if (type == 2) {
          // 送货
          field = 'back_id'
        } else if (type == 3) {
          // 调拨
          field = 'allot_id'
        }
        postAction('/taskAdmin/extract-info-detail/user-set', {
          id: row.id,
          field,
          value: val,
        }).then((r) => {
          this.$message.success('修改成功')
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
